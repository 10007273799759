import React, { useEffect } from "react";
import { render } from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";

function App() {
  useEffect(() => {
    // document.documentElement.style.cursor = 'none';

    // Clean-up function to reset the cursor when the component unmounts
    // return () => {
    //   document.documentElement.style.cursor = 'auto';
    // };
  }, []); // Empty 
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/Home" component={Home} exact />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
