import { useEffect, useRef, useState } from "react";
import "../src/tvStyle.css";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

const Read = ({
  logOutSelected,
  getReadSelectionId,
  setTabSelection,
  tabSelection,
  logOutButtonSelect,
  setLoaderRead,
  leftButtonLogountButtonSelect,
}) => {
  const [loader, setLoader] = useState(false);
  const [dataSave, setDataSave] = useState([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [pageSelectionIndex, setPageSelectionIndex] = useState(0);
  const [quizSelection, setQuizSelection] = useState(null);
  const [quizAnswerCheck, setQuizAnswerCheck] = useState(null);
  const [closeButtonSelect, setCloseButtonSelect] = useState(false);
  const [count, setCount] = useState(3);
  const [nextButtonSelect, setNextButtonSelect] = useState(true);
  const [previousButtonSelect, setPreviousButtonSelect] = useState(false);
  const [arrayLengthStrore, setArrayLengthStore] = useState([]);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [logounFirstTimeSelection, setFirstTimeLogountSelection] =
    useState(false);
  const history = useHistory();

  useEffect(() => {
    getReadListApiCall();
  }, []);

  useEffect(() => {
    const screenHeight = window.innerHeight;
    const handleKeyPress = (event) => {
      if (event.key == "Backspace") {
        setPopUpVisible(false);
        document.body.style.overflow = "unset";
        return;
      }
      switch (event.key) {
        case "ArrowUp":
          setFirstTimeLogountSelection(true);
          if (tabSelection == "") {
            if (!logOutSelected) {
              setTimeout(() => {
                window.scrollBy(0, -screenHeight / 2);
              }, 60);
              if (
                selectedItemIndex != 0 &&
                selectedItemIndex != 1 &&
                selectedItemIndex != 2 &&
                selectedItemIndex != 3
              ) {
                setSelectedItemIndex((prevIndex) =>
                  prevIndex > 0 ? prevIndex - 4 : 0
                );
              } else {
                getReadSelectionId(true);
                setSelectedItemIndex(null);
              }
            }
          }
          break;
        case "ArrowDown":
          if (selectedItemIndex == null) {
            setSelectedItemIndex(0);
            setTabSelection();
            getReadSelectionId(false);
            return;
          }
          if (logOutSelected == true) {
            getReadSelectionId(false);
            setSelectedItemIndex(0);
          } else {
            setTimeout(() => {
              window.scrollBy(0, screenHeight / 2);
            }, 60);
            if (selectedItemIndex + 4 < dataSave.length) {
              setSelectedItemIndex((prevIndex) => prevIndex + 4);
            }
          }
          break;
        case "ArrowLeft":
          setFirstTimeLogountSelection(true);
          if (tabSelection == "") {
            if (logOutSelected) {
              getReadSelectionId("false");
            } else {
              const filterData = arrayLengthStrore.filter(
                (item) => item == selectedItemIndex
              );
              if (filterData.length == 1) {
                setTimeout(() => {
                  window.scrollBy(0, -screenHeight / 2);
                }, 60);
              }
              setSelectedItemIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : prevIndex
              );
            }
          }
          break;
        case "ArrowRight":
          if (
            tabSelection == "Read" &&
            selectedItemIndex == null &&
            logounFirstTimeSelection
          ) {
            logOutButtonSelect();
            setFirstTimeLogountSelection(false);
          } else {
            if (tabSelection == "") {
              if (logOutSelected) {
              } else {
                const filterData = arrayLengthStrore.filter(
                  (item) => item == selectedItemIndex + 1
                );
                if (filterData.length == 1) {
                  setTimeout(() => {
                    window.scrollBy(0, screenHeight / 2);
                  }, 60);
                }
                setSelectedItemIndex((prevIndex) =>
                  prevIndex < dataSave.length - 1 ? prevIndex + 1 : prevIndex
                );
              }
            }
          }
          break;
        case "Enter":
          if (logOutSelected) {
            localStorage.removeItem("isLoggedIn");
            history.push("/Login");
            return;
          }
          if (selectedItemIndex != null) {
            setPopUpVisible(true);
            document.body.style.overflow = "hidden";
          }
          break;
        default:
          break;
      }
    };

    const handlePopKeyPress = (event) => {
      if (event.key == "Backspace") {
        clearData();
        setPopUpVisible(false);
        document.body.style.overflow = "unset";
        return;
      }
      switch (event.key) {
        case "ArrowUp":
          setCloseButtonSelect(true);
          setNextButtonSelect(false);
          setPreviousButtonSelect(false);
          break;
        case "ArrowDown":
          if (
            dataSave[selectedItemIndex].pages.length ==
            pageSelectionIndex + 1
          ) {
            setPreviousButtonSelect(true);
            setNextButtonSelect(false);
            setCloseButtonSelect(false);
          } else {
            setCloseButtonSelect(false);
            setNextButtonSelect(true);
            setPreviousButtonSelect(false);
          }
          break;
        case "ArrowLeft":
          if (pageSelectionIndex != 0) {
            if (nextButtonSelect) {
              setPreviousButtonSelect(true);
              setNextButtonSelect(false);
            }
          }
          break;
        case "ArrowRight":
          if (
            dataSave[selectedItemIndex].pages.length !=
            pageSelectionIndex + 1
          ) {
            if (previousButtonSelect) {
              setPreviousButtonSelect(false);
              setNextButtonSelect(true);
            }
          }
          break;
        case "Enter":
          if (closeButtonSelect) {
            clearData();
            return;
          }
          if (nextButtonSelect) {
            if (
              dataSave[selectedItemIndex].pages.length - 1 ==
              pageSelectionIndex + 1
            ) {
              setNextButtonSelect(false);
              setPreviousButtonSelect(true);
            }
            onClickNextButton();
          } else if (previousButtonSelect) {
            onClickPreviousButton();
          }
          break;
        default:
          break;
      }
    };

    const handleQuizKeyPress = (event) => {
      if (event.key == "Backspace") {
        clearData();
        setPopUpVisible(false);
        document.body.style.overflow = "unset";
        return;
      }
      switch (event.key) {
        case "ArrowUp":
          setQuizAnswerCheck(null);
          if (quizSelection == null) {
            setQuizSelection(0);
            setPreviousButtonSelect(false);
            setNextButtonSelect(false);
          } else {
            setQuizSelection(null);
            setCloseButtonSelect(true);
            setNextButtonSelect(false);
            setPreviousButtonSelect(false);
          }
          break;
        case "ArrowDown":
          setQuizAnswerCheck(null);
          if (closeButtonSelect) {
            setQuizSelection(0);
            setCloseButtonSelect(false);
            return;
          }
          if (
            quizSelection == 0 ||
            quizSelection == 1 ||
            quizSelection == 2 ||
            quizSelection == 3
          ) {
            setPreviousButtonSelect(true);
            setNextButtonSelect(false);
            setCloseButtonSelect(false);
            setQuizSelection(null);
            return;
          }
          if (
            dataSave[selectedItemIndex].pages.length ==
            pageSelectionIndex + 1
          ) {
            setPreviousButtonSelect(true);
            setNextButtonSelect(false);
            setCloseButtonSelect(false);
            setQuizSelection(null);
          } else {
            setCloseButtonSelect(false);
            setNextButtonSelect(true);
            setPreviousButtonSelect(false);
            setQuizSelection(null);
          }
          break;
        case "ArrowLeft":
          if (
            !nextButtonSelect &&
            !previousButtonSelect &&
            !closeButtonSelect
          ) {
            setQuizAnswerCheck(null);
            setQuizSelection((prev) => (prev > 0 ? prev - 1 : 0));
            return;
          }
          if (pageSelectionIndex != 0) {
            if (nextButtonSelect) {
              setPreviousButtonSelect(true);
              setNextButtonSelect(false);
            }
          }
          break;
        case "ArrowRight":
          if (
            !nextButtonSelect &&
            !previousButtonSelect &&
            !closeButtonSelect
          ) {
            setQuizAnswerCheck(null);
            setQuizSelection((prev) => (prev < 3 ? prev + 1 : 3));
            return;
          }

          if (
            dataSave[selectedItemIndex].pages.length !=
            pageSelectionIndex + 1
          ) {
            if (previousButtonSelect) {
              setPreviousButtonSelect(false);
              setNextButtonSelect(true);
            }
          }
          break;
        case "Enter":
          if (
            quizSelection == 0 ||
            quizSelection == 1 ||
            quizSelection === 2 ||
            quizSelection == 3
          ) {
            let isTrueGet =
              dataSave[selectedItemIndex].pages[pageSelectionIndex].option[
                quizSelection
              ];
            if (isTrueGet.isTrue) {
              setQuizAnswerCheck(true);
              setPreviousButtonSelect(false);
              setNextButtonSelect(false);
              setTimeout(() => {
                setCount(3);
                setQuizSelection(0);
                setQuizAnswerCheck(null);
                setPageSelectionIndex((prevIndex) => prevIndex + 1);
              }, 3000);
            } else {
              setQuizAnswerCheck(false);
            }
            return;
          }

          if (closeButtonSelect) {
            clearData();
            return;
          }
          if (nextButtonSelect) {
            if (
              dataSave[selectedItemIndex].pages.length - 1 ==
              pageSelectionIndex + 1
            ) {
              setNextButtonSelect(false);
              setPreviousButtonSelect(true);
            }
            onClickNextButton();
          } else if (previousButtonSelect) {
            onClickPreviousButton();
          }
          break;
        default:
          break;
      }
    };

    if (!popUpVisible) {
      window.addEventListener("keydown", handleKeyPress);
    } else {
      if (
        dataSave[selectedItemIndex].pages[pageSelectionIndex].type == "QUIZE"
      ) {
        window.addEventListener("keydown", handleQuizKeyPress);
      } else {
        window.addEventListener("keydown", handlePopKeyPress);
      }
    }

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      window.removeEventListener("keydown", handlePopKeyPress);
      window.removeEventListener("keydown", handleQuizKeyPress);
    };
  }, [
    dataSave,
    selectedItemIndex,
    pageSelectionIndex,
    logOutSelected,
    closeButtonSelect,
    popUpVisible,
    nextButtonSelect,
    previousButtonSelect,
    quizSelection,
  ]);

  useEffect(() => {
    if (quizAnswerCheck) {
      if (count > 0) {
        const intervalId = setInterval(() => {
          setCount((prevCount) => prevCount - 1);
        }, 1000);
        return () => clearInterval(intervalId);
      }
    }
  }, [quizAnswerCheck, count]);

  const getReadListApiCall = (item) => {
    const token = localStorage.getItem("token");
    setLoader(true);
    setLoaderRead(true);
    // const headers = {
    //   Authorization: `Bearer ${token}`,
    // };

    const baseUrl = "https://api.ambanilearn.com/api/v1/readsV2/getReadForTv";
    const url = `${baseUrl}?page=${1}&token=Bearer ${token}&page=1&limit=10000`;
    axios
      .get(url)
      .then((response) => {
        setLoader(false);
        setLoaderRead(false);
        setFirstTimeLogountSelection(true);
        setDataSave(response.data.data);
        const newArray = [];
        for (let i = 0; i < response.data.data.length; i++) {
          newArray.push(((i % 4) + 1) * 4);
          if (newArray.length === 4) break;
        }
        setArrayLengthStore(newArray);
      })
      .catch((error) => {
        setLoader(false);
        setLoaderRead(false);
        console.error("Error fetching data:", error);
      });
  };

  const onClickNextButton = () => {
    setPageSelectionIndex((prevIndex) => prevIndex + 1);
  };
  const onClickPreviousButton = () => {
    if (pageSelectionIndex == 1) {
      setNextButtonSelect(true);
      setPreviousButtonSelect(false);
    }
    setPageSelectionIndex((prevIndex) => prevIndex - 1);
  };

  const clearData = () => {
    setPopUpVisible(false);
    document.body.style.overflow = "unset";
    setPageSelectionIndex(0);
    setNextButtonSelect(true);
    setCloseButtonSelect(false);
    setPreviousButtonSelect(false);
  };

  const isAudioType = (s) => {
    var audioTypes = [".mp3", ".wav"],
      audioExt = s.replace(/^.+(?=\.)/i, "");
    return audioTypes.indexOf(audioExt.toLowerCase()) > -1;
  };

  const onClickSelectedItemRead = (item, index) => {
    setPopUpVisible(true);
    document.body.style.overflow = "hidden";
    setSelectedItemIndex(index);
    setTabSelection("");
    getReadSelectionId(false);
  };

  const onClickQuizSelectedGame = (item, index) => {
    setQuizSelection(index);
    setPreviousButtonSelect(false);
    setNextButtonSelect(false);

    let isTrueGet =
      dataSave[selectedItemIndex].pages[pageSelectionIndex].option[index];
    if (isTrueGet.isTrue) {
      setQuizAnswerCheck(true);
      setTimeout(() => {
        setCount(3);
        setQuizSelection(0);
        setQuizAnswerCheck(null);
        setPageSelectionIndex((prevIndex) => prevIndex + 1);
      }, 3000);
    } else {
      setQuizAnswerCheck(false);
    }
  };

  const selectedItemView = () => {
    return (
      <Modal
        ariaHideApp={false}
        onRequestClose={() => {
          clearData();
        }}
        style={{
          overlay: {
            background: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 11,
          },
          content: {
            border: "1px solid rgba(0, 0, 0, 0.7)",
            padding: 0,
            margin: 0,
          },
        }}
        isOpen={popUpVisible}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              background: "#76cfff",
              display: "flex",
              height: "15%",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "95%",
                justifyContent: "center",
                alignSelf: "center",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                alt=""
                style={{
                  objectFit: "contain",
                  width: "7%",
                }}
                src={require("../src/assets/images/ic_ambani_logo.png")}
              />
              <div
                style={{
                  color: "#FFF",
                  display: "flex",
                  fontSize: "25px",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  flex: 1,
                  fontFamily: "Inter-Bold",
                }}
              >
                {dataSave[selectedItemIndex].title}
              </div>

              <div
                style={{
                  width: "3%",
                }}
              >
                <img
                  alt=""
                  onClick={() => clearData()}
                  style={{
                    cursor: "pointer",
                    border: closeButtonSelect
                      ? "1px solid pink"
                      : "0px solid red",
                    boxShadow: closeButtonSelect
                      ? "1px 1px 20px 1px red"
                      : "0px 0px 0px 0px pink",
                    borderRadius: "10px",
                    // cursor: "pointer",
                    objectFit: "contain",
                    width: "100%",
                  }}
                  src={require("../src/assets/images/ic_close.png")}
                />
              </div>
            </div>
          </div>

          {dataSave[selectedItemIndex].pages[pageSelectionIndex].type ==
          "PAGE" ? (
            <div
              style={{
                background: "#fff",
                display: "flex",
                height: "85%",
                width: "100%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {dataSave[selectedItemIndex].pages[pageSelectionIndex].type ==
                "PAGE" && (
                <img
                  alt=""
                  style={{
                    objectFit: "contain",
                    height: "88%",
                    marginTop: "10px",
                  }}
                  src={
                    dataSave[selectedItemIndex].pages[
                      pageSelectionIndex
                    ].media.find((item) => item.mediaType === "Image").mediaUrl
                  }
                />
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent:
                    pageSelectionIndex == 0 ? "end" : "space-between",
                  alignItems: "center",
                  width: "95%",
                  marginTop: "5px",
                  alignItems: "center",
                }}
              >
                {pageSelectionIndex != 0 && (
                  <button
                    onClick={() => {
                      setNextButtonSelect(false);
                      setPreviousButtonSelect(true);
                      onClickPreviousButton();
                    }}
                    className="button"
                    style={{
                      cursor: "pointer",
                      border: previousButtonSelect
                        ? "1px solid pink"
                        : "0px solid red",
                      boxShadow: previousButtonSelect
                        ? "1px 1px 20px 1px red"
                        : "0px 0px 0px 0px pink",
                    }}
                  >
                    Previous
                  </button>
                )}
                {dataSave[selectedItemIndex].pages[
                  pageSelectionIndex
                ].media.find((item) => item?.mediaType === "Audio") && (
                  <audio
                    style={{
                      flexGrow: 1,
                      marginLeft: "20px",
                      padding: "0 20px 4px",
                      marginRight: "20px",
                      height: "40px",
                    }}
                    src={
                      dataSave[selectedItemIndex].pages[
                        pageSelectionIndex
                      ].media.find((item) => item.mediaType === "Audio")
                        .mediaUrl
                    }
                    controls
                    autoPlay={true}
                  />
                )}
                {dataSave[selectedItemIndex].pages.length !=
                  pageSelectionIndex + 1 && (
                  <button
                    onClick={() => {
                      setNextButtonSelect(true);
                      setPreviousButtonSelect(false);
                      onClickNextButton();
                    }}
                    style={{
                      cursor: "pointer",
                      border: nextButtonSelect
                        ? "1px solid pink"
                        : "0px solid red",
                      boxShadow: nextButtonSelect
                        ? "1px 1px 20px 1px red"
                        : "0px 0px 0px 0px pink",
                    }}
                    className="button"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                background: "#fff",
                display: "flex",
                height: "85%",
                width: "100%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "95%",
                  marginTop: "10px",
                  height: "88%",
                }}
              >
                {isAudioType(
                  dataSave[selectedItemIndex]?.pages[pageSelectionIndex]
                    ?.questionUrl
                ) ? (
                  <audio
                    style={{
                      width: "95%",
                      marginLeft: "20px",
                      padding: "0 20px 4px",
                      marginRight: "20px",
                      height: "45px",
                    }}
                    src={
                      dataSave[selectedItemIndex].pages[pageSelectionIndex]
                        .questionUrl
                    }
                    controls
                    autoPlay={true}
                  />
                ) : (
                  <div
                    style={{
                      color: "#fe0e78",
                      display: "flex",
                      textAlign: "center",
                      fontSize: "22px",
                      textAlign: "center",
                      marginTop: "10px",
                      flex: 1,
                      justifyContent: "center",
                      fontFamily: "Inter-Bold",
                    }}
                  >
                    {
                      dataSave[selectedItemIndex].pages[pageSelectionIndex]
                        .question
                    }
                  </div>
                )}
                <div
                  style={{
                    height: "80%",
                    marginTop: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "center",
                    display: "flex",
                  }}
                >
                  {dataSave[selectedItemIndex].pages[
                    pageSelectionIndex
                  ].option.map((item, index) => {
                    return (
                      <img
                        onClick={() => onClickQuizSelectedGame(item, index)}
                        alt=""
                        style={{
                          cursor: "pointer",
                          objectFit: "contain",
                          height: "80%",
                          borderRadius: "5px",
                          border:
                            quizSelection == index
                              ? quizAnswerCheck == null
                                ? "1px solid grey"
                                : quizAnswerCheck
                                ? "1px solid green"
                                : "1px solid red"
                              : "0px solid red",
                          boxShadow:
                            quizSelection == index
                              ? quizAnswerCheck == null
                                ? "1px 1px 20px 1px grey"
                                : quizAnswerCheck
                                ? "1px 1px 20px 1px green"
                                : "1px 1px 20px 1px red"
                              : "0px 0px 0px 0px pink",
                        }}
                        src={item.optionUrl}
                      />
                    );
                  })}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent:
                    pageSelectionIndex == 0 ? "end" : "space-between",
                  alignItems: "center",
                  width: "95%",
                  marginTop: "5px",
                  alignItems: "center",
                }}
              >
                {pageSelectionIndex != 0 && (
                  <button
                    onClick={() => onClickPreviousButton()}
                    className="button"
                    style={{
                      cursor: "none",
                      border: previousButtonSelect
                        ? "1px solid pink"
                        : "0px solid red",
                      boxShadow: previousButtonSelect
                        ? "1px 1px 20px 1px red"
                        : "0px 0px 0px 0px pink",
                    }}
                  >
                    Previous
                  </button>
                )}

                {quizAnswerCheck && (
                  <div
                    style={{
                      color: "#fe0e78",
                      display: "flex",
                      textAlign: "center",
                      fontSize: "22px",
                      textAlign: "center",
                      marginTop: "10px",
                      flex: 1,
                      justifyContent: "center",
                      fontFamily: "Inter-Bold",
                    }}
                  >
                    Please wait : {count}
                    {/* {quizAnswerCheck && "Please wait : "+ count } */}
                  </div>
                )}

                {dataSave[selectedItemIndex].pages[pageSelectionIndex].type ==
                  "PAGE" && (
                  <div>
                    {dataSave[selectedItemIndex].pages.length !=
                      pageSelectionIndex + 1 && (
                      <button
                        onClick={() => onClickNextButton()}
                        style={{
                          cursor: "none",
                          border: nextButtonSelect
                            ? "1px solid pink"
                            : "0px solid red",
                          boxShadow: nextButtonSelect
                            ? "1px 1px 20px 1px red"
                            : "0px 0px 0px 0px pink",
                        }}
                        className="button"
                      >
                        Next
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  };

  const FreeDaTa = () => {
    return (
      <div
        style={{
          width: "100%",
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!loader ? (
          <div>
            {dataSave.length > 0 && (
              <div
                style={{
                  fontSize: "17px",
                  marginBottom: "10px",
                  marginTop: "20px",
                  fontWeight: "600",
                  fontFamily: "Inter",
                }}
              >
                {"Books"}
              </div>
            )}

            <div className="grid-view">
              {dataSave.map((item, index) => (
                <div
                  onClick={() => onClickSelectedItemRead(item, index)}
                  style={{ cursor: "pointer"}}
                  key={index}
                  className={`grid-item ${
                    selectedItemIndex === index ? "grid-item-selected" : ""
                  }`
                }
                >
                  <div className="card">
                    <img
                      style={{
                        borderRadius: "10px",
                        width: "21vw",
                        objectFit: "contain",
                        height: "200px",
                      }}
                      src={
                        item.photo ??
                        require("../src/assets/images/image_default_read.png")
                      }
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      color: "#000",
                      alignSelf: "flex-start",
                      width: "21vw",
                      fontFamily: "Inter-Bold",
                    }}
                  >
                    {item.title}
                  </div>
                  <div
                    style={{
                      width: "21vw",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <b
                      style={{
                        color: "#282345",
                        opacity: "30%",
                        fontSize: "12px",
                        fontFamily: "Roboto-Regular",
                        flex: 4,
                        paddingTop: "5px",
                      }}
                    >
                      {item.description}
                    </b>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        border:
                          selectedItemIndex === index
                            ? "1px solid pink"
                            : "0px solid red",
                        // boxShadow:
                        //   selectedItemIndex === index
                        //     ? "1px 1px 20px 1px red"
                        //     : "0px 0px 0px 0px pink",
                      }}
                      className="button"
                    >
                      Read
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            style={{
              height: 400,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <ClipLoader
              color={"#fe0e78"}
              loading={true}
              size={80}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "1vh",
        }}
      >
        {<div className="mainDiv2">{FreeDaTa()}</div>}

        {!loader && (
          <div className="mainDiv2">
            <article className="article">
              <img
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
                alt=""
                src={require("../src/assets/footer_image.png")}
              ></img>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "14vw",
                }}
                className="header"
              ></div>
            </article>
          </div>
        )}

        {popUpVisible && selectedItemView()}
      </div>
    </div>
  );
};

export default Read;
